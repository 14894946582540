import { db } from 'controllers/db'
import { DBT } from 'types/internal'
import {
  collection,
  deleteField,
  doc,
  FieldValue,
  onSnapshot,
  setDoc,
  updateDoc
} from 'firebase/firestore'
import _ from 'lodash'
import { httpsCallable } from 'firebase/functions'
import { functions } from 'controllers/db'

export const dbSubscribeToTest = (
  testCase: string,
  callback: (tests: DBT.Test[]) => void
) => {
  try {
    const ref = doc(collection(db, 'tests'), testCase)
    const unsubscribe = onSnapshot(
      ref,
      sn => {
        const tests = sn.data() as DBT.Test[]
        if (tests) {
          callback(_.orderBy(tests, 'createdAt', 'desc'))
        }
      },
      err => {
        console.error(`dbSubscribeToTest error: ${err.message}`)
      }
    )
    return unsubscribe
  } catch (e) {
    console.error('dbSubscribeToTest error', e)
    return null
  }
}

export const dbSaveTest = async (testCase: string, test: DBT.Test) => {
  try {
    const ref = doc(collection(db, 'tests'), testCase)
    await setDoc(ref, { [test.id]: test }, { merge: true })
    console.info(
      `Test with ID ${test.id} in testCase ${testCase} updated successfully.`
    )
  } catch (e) {
    console.error(
      `Failed to update test with ID ${
        test.id
      } in testCase ${testCase}: ${_.get(e, 'message')}`
    )
  }
}

export const dbDeleteTest = async (testCase: string, testId: string) => {
  try {
    const ref = doc(collection(db, 'tests'), testCase)
    await updateDoc(ref, { [testId]: deleteField() })
    console.info(
      `Test with ID ${testId} in testCase ${testCase} deleted successfully.`
    )
  } catch (e) {
    console.error(
      `Failed to delete test with ID ${testId} in testCase ${testCase}: ${_.get(
        e,
        'message'
      )}`
    )
  }
}

export const dbRunTest = async (testCase: string, testId: string) => {
  try {
    console.info(
      'Starting runTest function for testCase:',
      testCase,
      'and testId:',
      testId
    )
    const f = httpsCallable(functions, 'runTest')
    const res = await f({
      testCase,
      testId
    })
    console.info('runTest function response:', res.data)
    return res.data
  } catch (e) {
    console.error('Error in runTest function:', _.get(e, 'message'))
    return { error: _.get(e, 'message') }
  }
}
