import {
  ForwardRefRenderFunction,
  forwardRef,
  useImperativeHandle,
  Ref,
  useState
} from 'react'
import _ from 'lodash'
import {
  Modal,
  ModalBody,
  ModalOverlay,
  ModalContent,
  useDisclosure,
  ModalHeader,
  ModalFooter,
  Button,
  FormControl,
  VStack,
  ModalCloseButton,
  Input
} from '@chakra-ui/react'
import { generateId } from 'controllers/db'
import Label from 'components/Label'
import { dbCreateVegasUser } from 'controllers/vegas'
import JsonInput from 'pages/vegasUsers/JsonInput'

export interface IAddVegasUserModal {
  open: () => void
}

type Props = {
  onUserCreated: (userId: string) => void
}

const AddVegasUserModal: ForwardRefRenderFunction<IAddVegasUserModal, Props> = (
  { onUserCreated },
  ref: Ref<unknown>
) => {
  const [name, setName] = useState('')
  const [user, setUser] = useState({})
  const { isOpen, onOpen, onClose } = useDisclosure()

  useImperativeHandle(ref, () => ({
    open: () => {
      setName('')
      onOpen()
    }
  }))

  const onCreate = () => {
    const u = {
      name,
      ...user
    }
    if (!_.isEmpty(u.name)) {
      const userId = 'u_' + generateId()
      const user: Record<string, any> = {
        id: userId,
        ...u
      }
      dbCreateVegasUser(user)
      onUserCreated(userId)
      onClose()
    }
  }

  const renderContent = () => {
    return (
      <FormControl isRequired w='full'>
        <Label>User name</Label>
        <Input
          id='name'
          type='text'
          value={name}
          onChange={e => setName(e.target.value)}
          onKeyDown={async e => {
            if (e.key === 'Enter') {
              onCreate()
            }
          }}
        />
        <JsonInput data={user} onChange={setUser} />
      </FormControl>
    )
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size='2xl' autoFocus={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Add user</ModalHeader>
        <ModalCloseButton />
        <ModalBody pt={4}>
          <VStack spacing={8}>{renderContent()}</VStack>
        </ModalBody>
        <ModalFooter>
          <Button variant={'primary'} onClick={onCreate}>
            {'Add'}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default forwardRef(AddVegasUserModal)
