import React from 'react'
import {
  Button,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverBody,
  VStack,
  Text
} from '@chakra-ui/react'
import { ChevronDownIcon } from '@chakra-ui/icons'
import { DBT } from 'types/internal'
import dayjs from 'dayjs'

interface VegasTemplatesProps {
  items: DBT.AIConfTemplateT[]
  onItemClick: (item: DBT.AIConfTemplateT) => void
}

const VegasTemplates: React.FC<VegasTemplatesProps> = ({
  items,
  onItemClick
}) => {
  return (
    <Popover>
      <PopoverTrigger>
        <Button rightIcon={<ChevronDownIcon />} variant='unstyled' size='sm'>
          Load template
        </Button>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverBody>
          <VStack align='start' spacing={2}>
            {items.map((item, index) => (
              <Button
                key={index}
                variant='ghost'
                onClick={() => onItemClick(item)}
                justifyContent='flex-start'
                w='full'
              >
                <VStack spacing={0.5} align={'start'}>
                  <Text color='zinc.800' fontSize='sm' fontWeight={'semibold'}>
                    {item.name}
                  </Text>
                  <Text fontSize='xs' color='zinc.600' fontWeight={'normal'}>
                    {dayjs(item.createdAt).format('lll')}
                  </Text>
                </VStack>
              </Button>
            ))}
          </VStack>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  )
}

export default VegasTemplates
