import {
  Box,
  Button,
  Checkbox,
  Circle,
  Flex,
  HStack,
  Image,
  Text,
  useToast,
  VStack
} from '@chakra-ui/react'
import { dbSaveTest } from 'controllers/tests'
import { FC, useState } from 'react'
import SimpleTextarea from 'shared/components/SimpleTextarea'
import { DBT } from 'types/internal'
import { ChevronUpIcon, ChevronDownIcon } from '@chakra-ui/icons'
import JsonInput from 'pages/vegasUsers/JsonInput'
import dayjs from 'dayjs'
import _ from 'lodash'
import Label from 'components/Label'
import FilesPickerButton from 'components/FilesPickerButton'
import ImagePaste from 'components/ImagePaste'
import { FileWithPath } from 'react-dropzone/.'
import { saveFileObject } from 'controllers/storage'

interface Props {
  testCase: string
  t: DBT.Test
  isProcessing: boolean
  onRun: () => void
  onDelete: () => void
  isSelected: boolean
  toggleSelect: () => void
  onDuplicate: () => void
}

const Test: FC<Props> = ({
  testCase,
  t,
  isProcessing,
  onRun,
  onDelete,
  isSelected,
  toggleSelect,
  onDuplicate
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [processingFiles, setProcessingFiles] = useState(false)
  const toast = useToast()

  const toggleCollapsible = () => setIsOpen(!isOpen)

  const update = (upd: Partial<DBT.Test>) => {
    dbSaveTest(testCase, { ...t, ...upd })
  }

  const uploadFileToStorage = async (f: FileWithPath | File) => {
    const fileExt = _.last(_.split(f.name, '.'))
    const filename = `${t.id}.${fileExt}`
    const storagePath = `tests/${filename}`
    const storageFile = await saveFileObject(f, storagePath)
    return storageFile
  }

  const onFilesPicked = async (files: File[] | FileWithPath[]) => {
    console.log('on files picked', files)
    if (files.length > 0) {
      try {
        const f = files[0]
        setProcessingFiles(true)
        const storageFile = await uploadFileToStorage(f)
        if (storageFile) {
          console.log('storageFiles', storageFile)
          const url = storageFile.url
          update({ images: [url] })
        }
        setProcessingFiles(false)
      } catch (e) {
        console.error(e)
        setProcessingFiles(false)
        toast({
          title: 'Error',
          description: 'Failed to upload files, check console logs',
          status: 'error',
          duration: 3000,
          isClosable: true
        })
      }
    }
  }

  const renderImageInput = () => {
    return (
      <VStack pt={6} w='full' align={'start'}>
        <Label>Images:</Label>
        <HStack>
          <FilesPickerButton
            options={{
              accept: { 'image/jpeg': [], 'image/png': [], 'image/heic': [] }
            }}
            onFilesPicked={onFilesPicked}
            isLoading={processingFiles}
          />
          <ImagePaste onSelect={onFilesPicked} />
        </HStack>
        <VStack w='full' pt={6} spacing={6} align={'start'}>
          {_.map(t.images, url => {
            return (
              <Box
                boxSize={'lg'}
                borderWidth={1}
                borderColor={'zinc.200'}
                bg='zinc.100'
                rounded={'md'}
              >
                <Image w='full' h='full' objectFit={'contain'} src={url} />
              </Box>
            )
          })}
        </VStack>
      </VStack>
    )
  }

  const renderConversationSettings = () => {
    return (
      <HStack w='full' align={'start'}>
        <VStack align={'start'} spacing={0} flex={1}>
          <Text color='zinc.500' fontWeight={'semibold'} fontSize={'sm'}>
            Conversation
          </Text>
          <JsonInput
            data={t.conversation}
            onChange={c => {
              update({ conversation: c as DBT.TestPhrase[] })
            }}
          />
        </VStack>
        <VStack align={'start'} spacing={0} flex={1}>
          <Text color='zinc.500' fontWeight={'semibold'} fontSize={'sm'}>
            Data
          </Text>
          <JsonInput
            data={t.data}
            onChange={c => {
              update({ data: c as Record<string, any> })
            }}
          />
        </VStack>
      </HStack>
    )
  }

  const renderSettings = () => {
    switch (testCase) {
      case 'demo2':
        return renderConversationSettings()
      case 'liveness':
        return renderImageInput()
      default:
        return null
    }
  }

  const renderLastRun = () => {
    return (
      <VStack w='20' align={'start'}>
        <Text fontSize={'sm'} color={'zinc.600'} fontWeight={'medium'}>
          Last run
        </Text>
        <Text fontSize={'xs'} color={'zinc.800'}>
          {t.lastTestTimestamp ? dayjs(t.lastTestTimestamp).format('lll') : '-'}
        </Text>
      </VStack>
    )
  }

  const renderLatency = () => {
    return (
      <VStack w='20' align={'start'}>
        <Text fontSize={'sm'} color={'zinc.600'} fontWeight={'medium'}>
          AI Latency
        </Text>
        <Text fontSize={'xs'} color={'zinc.800'}>
          {t.latency ? _.round(_.toNumber(t.latency) / 1000, 2) : '-'}
        </Text>
      </VStack>
    )
  }

  const renderAIResponse = () => {
    const keys = _.keys(t.aiResponse).sort()
    return (
      <VStack flex='1.5' flexShrink={0} spacing={0} align={'start'}>
        <Text fontSize={'sm'} color={'zinc.600'} fontWeight={'medium'}>
          AI Response
        </Text>
        {_.map(keys, k => {
          const v = _.get(t, ['aiResponse', k], '')
          const stringV = _.isObject(v) ? JSON.stringify(v) : _.toString(v)
          return (
            <Text fontSize={'xs'} key={k} as='p'>
              <b>{k}</b>:{' '}
              <Text as='span' color={k === 'reply' ? 'blue.600' : 'gray.700'}>
                {stringV}
              </Text>
            </Text>
          )
        })}
      </VStack>
    )
  }

  const renderPassed = () => {
    return (
      <VStack w={20}>
        <Text fontSize={'sm'} color={'zinc.600'} fontWeight={'medium'}>
          Is Passed
        </Text>
        <Circle
          size={4}
          bgColor={
            _.has(t, 'isPassed')
              ? t.isPassed
                ? 'green.600'
                : 'red.600'
              : 'white'
          }
        />
      </VStack>
    )
  }

  return (
    <VStack
      w='full'
      align={'start'}
      role='group'
      minH='8'
      p={2}
      spacing={2}
      flexShrink={0}
    >
      <HStack w='full' align={'start'}>
        <Flex w='6' justify={'start'} align='start'>
          <Checkbox
            colorScheme='zinc'
            size='md'
            isChecked={isSelected}
            onChange={() => toggleSelect()}
          />
        </Flex>
        <VStack flex='1' flexShrink={0}>
          <SimpleTextarea
            fontSize={'md'}
            fontWeight={'medium'}
            variant={'unstyled'}
            value={t.title}
            p={0}
            onChange={(title: string) => update({ title })}
            colorScheme='zinc'
          />
          <SimpleTextarea
            placeholder='How to validate'
            value={t.validation}
            onChange={(validation: string) => update({ validation })}
            colorScheme='zinc'
          />
          <HStack w='full' justify={'space-between'}>
            <Button
              onClick={toggleCollapsible}
              size='xs'
              variant={'link'}
              // visibility={isOpen ? 'visible' : 'hidden'}
              // _groupHover={{ visibility: 'visible' }}
            >
              {isOpen ? 'hide settings ' : 'show settings'}
              {isOpen ? <ChevronUpIcon ml={1} /> : <ChevronDownIcon ml={1} />}
            </Button>
            <Button
              size='xs'
              variant={'link'}
              // colorScheme='red'
              // visibility={isOpen ? 'visible' : 'hidden'}
              // _groupHover={{ visibility: 'visible' }}
              onClick={onDuplicate}
            >
              duplicate
            </Button>
            <Button
              size='xs'
              variant={'link'}
              colorScheme='red'
              // visibility={isOpen ? 'visible' : 'hidden'}
              // _groupHover={{ visibility: 'visible' }}
              onClick={onDelete}
            >
              delete test
            </Button>
          </HStack>
        </VStack>
        {renderLastRun()}
        {renderLatency()}
        {renderAIResponse()}
        {renderPassed()}
        <Button
          ml={8}
          variant={'primary'}
          isLoading={isProcessing}
          onClick={onRun}
        >
          Run
        </Button>
      </HStack>

      {isOpen && renderSettings()}
    </VStack>
  )
}

export default Test
